import React, { useState, useEffect } from "react";
import { sectionWrapper } from "../hoc";
import { gql, GraphQLClient } from "graphql-request";
import { HiArrowRight } from "react-icons/hi";

const MY_QUERY = gql`
  query MyQuery {
    portfolios(where: { selectPortfolioCategory_in: Ecommerce }) {
      id
      link
      selectPortfolioCategory
      portfolioTitle
      portfolioAssetImage {
        url
      }
    }
  }
`;

function EcomPortfolio() {
  const [data, setData] = useState(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  const portfolioSlice = showMore ? data.portfolios : data.portfolios.slice(0, 5);

  const handleLoadMore = () => {
    setShowMore(true);
  };

  return (
    <div class="flex  flex-wrap items-start justify-center gap-x-4">
      {portfolioSlice.map((post) => (
        <div className="w-full my-5 sm:w-1/3 md:w-1/3 xl:w-1/6">
          <div key={post.id} className="w-full">
            <p className="font-bold text-center text-white">
              <a href={post.link}>{post.portfolioTitle}</a>
            </p>

            <div
              style={{
                backgroundImage: `url(${post.portfolioAssetImage.url})`,
              }}
              className="bg-cover min-h-[400px] bg-no-repeat bg-top-center rounded-md  hover:bg-bottom transition duration-1500 ease-in-out"
            />

            <p class="text-white text-center flex flex-row items-center justify-center gap-2">
              <a href={post.link} target="_blank" class="relative group">
                <span class="group-hover:text-gray-300 ">
                  Visit Site
                  <span class="absolute top-1 transform translate-x-2 transition-transform duration-300 group-hover:translate-x-4">
                    <HiArrowRight />
                  </span>
                </span>
              </a>
            </p>
          </div>
        </div>
      ))}
      {!showMore && (
        <div className="w-full mt-4 text-center">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default EcomPortfolio;
