import { gql, GraphQLClient } from "graphql-request";
import { PayPalButton } from "react-paypal-button-v2"; // Import the PayPal subscription button component
import { sectionWrapper } from "../hoc";
import { motion } from "framer-motion";
import { fadeIn, textVariant } from "../utils/motion";
import { services } from "../constant";
import ReactHtmlParser from 'react-html-parser';
import { AiFillCheckCircle } from "react-icons/ai";
import { useEffect, useState } from "react";


const MY_QUERY = gql`
  query MyQuery {
    allPackages(where: {selectCategory_in: Subscriptions}) {
      id
      packagesDescriptions {
        html
      }
      packageTitle
      packagePrice
      selectCategory
      link
    }
  }
`;

const Modal = ({ open, onClose, post }) => {
  if (!open) return null;
  return (
    <div onClick={onClose} className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='modalContainer'
      >
        <div className='modalRight'>
          <p className='text-black closeBtn text-[20px] cursor-pointer' onClick={onClose}>
            X
          </p>
          <div className='content'>
            <p className="text-black font-semibold text-[26px] text-center">{post.packageTitle}</p>
            <p className="text-[#b65ef4] text-center font-bold text-[36px]">
              ${post.packagePrice}
            </p>
            <div className="h-96 scroll-smooth md:scroll-auto overflow-y-auto text-[15px] bg-[#0b081c] rounded-md p-3 ml-auto mr-auto">
              {ReactHtmlParser(post.packagesDescriptions.html)}
            </div>
            <h1>$20 CREDIT</h1>
            <p>for your first trade?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

function SubsPackages() {



  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  const handleClick = (post) => {
    setOpenModal(post);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (!data) return <div>Loading...</div>;

  return (
    <div className="flex flex-wrap items-start justify-center gap-x-4">
      {data.allPackages.map((post) => (
        <div className="xl:w-1/4 sm:w-1/3 md:w-1/3" key={post.id}>
          <div className="w-[24rem] green-pink-gradient p-[1px] rounded-[20px] shadow-card m-5">
            <div className="mt-0 bg-tertiary rounded-[20px] p-5 px-4 justify-center m-0 py-10">
              <h2 className="font-semibold text-[26px] text-center">{post.packageTitle}</h2>
              <p className="text-[#b65ef4] text-center font-bold text-[36px]">
                ${post.packagePrice}
              </p>
              <div className="items-center justify-center">
                {/* Your package description */}
                <div className="h-60 scroll-smooth md:scroll-auto overflow-y-auto text-[15px] bg-[#0b081c] rounded-md p-3 ml-auto mr-auto">
                  {ReactHtmlParser(post.packagesDescriptions.html)}
                </div>
                {/* Button section */}
                <div className="flex flex-row items-center justify-center gap-6">
                  <div>
                    <button
                      onClick={() => handleClick(post)}
                      type="submit"
                      className="bg-tertiary w-fit py-3 px-2 outline-none border-none text-[12px] shadow-md shadow-primary rounded-xl"
                    >
                      Read More
                    </button>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="bg-tertiary w-fit py-3 px-2 outline-none border-none text-[12px] shadow-md shadow-primary rounded-xl"
                    >
                      (718) 785-3776
                    </button>
                  </div>
                  <div>
               
               <button
 type="submit"
 className="bg-tertiary w-fit py-3 px-2 outline-none border-none font-medium text-[15px] shadow-md shadow-primary rounded-xl flex items-center justify-center"
>
 <a href={`${post.link}`} target="_blank" className="text-white">
   BUY NOW
 </a>
</button>
</div>
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
      ))}
      {openModal && (
        <Modal open={true} onClose={handleCloseModal} post={openModal} />
      )}
    </div>
  );
}

export default SubsPackages;