
import EcomPortfolio from "../components/EcomPortfolio";
import Footer from "../components/Footer";
import LogoPortfolio from "../components/LogoPortfolio";
import Navbar from "../components/Navbar";
import WebPortfolio from "../components/WebPortfolio";
import { sectionWrapper } from "../hoc";

import PortfolioHero from "../section/PortfolioHero";
import { Helmet } from 'react-helmet';


import { useState } from 'react';
import PortfolioAppGallery from "../components/PortfolioAppGallery";
import AppGalleries from "../components/AppGalleries";





function WebPage() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="relative z-0 bg-[#060816]">
        <Helmet>
<title>Web Design Portfolio - The Designsinc.</title>
</Helmet>
    <div>
      <Navbar />
      <PortfolioHero />

      <div  className="pb-5" >
      
            <WebPortfolio />
            </div>
         

      <Footer />
    </div>
    </div>
  );
}

export default WebPage;

