import Carousel from '../components/Carousel'
import Contact from '../components/Contact'
import Feedbacks from '../components/Feedbacks'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import EcommerceHero from '../section/EcommerceHero'
import IdeaSectionAbout from '../section/IdeaSectionAbout'
import React from 'react'
import EcomPortfolio from '../components/EcomPortfolio'
import PartnersLogos from '../components/PartnersLogos'
import { Helmet } from 'react-helmet';
import IdeaEcomSection from '../section/IdeaEcomSection'



function Ecommerce() {
  return (
    <div className="relative z-0 bg-[#060816]">
        <Helmet>
        <title>Ecommerce Website - The Designsinc.</title>
      </Helmet>
       <div className="bg-center bg-no-repeat bg-cover bg-hero-pattern">
 
      <Navbar />
      
        {/** About Section */}
       <EcommerceHero />
       </div>
       {/**Slider logos company */}
       <PartnersLogos />

        {/**idea section and btns */}
        <IdeaEcomSection />


        {/** Ecommerce Portfolios */}
       <h2 className="text-center p-3 font-bold text-[36px] text-white">E-Commerce Portfolio</h2>
      <EcomPortfolio />

        {/** Testimonials */}
        <div className="">
        <Feedbacks  />
        </div>
        {/** contact section */}
        <div className='relative z-0'>
      <Contact />
     
    </div>
      
   

    <Footer />
  </div>
  )
}

export default Ecommerce