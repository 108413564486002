import Carousel from "../components/Carousel";
import Contact from "../components/Contact";
import Feedbacks from "../components/Feedbacks";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import IdeaSectionAbout from "../section/IdeaSectionAbout";
import WebDesignHero from "../section/WebDesignHero";
import React from "react";
import WebPortfolio from "../components/WebPortfolio";
import PartnersLogos from "../components/PartnersLogos";
import { Helmet } from 'react-helmet';
import IdeasWebSection from "../section/IdeasWebSection";

function WebsiteDesign() {
  return (
    <div className="relative z-0 bg-[#060816]">
        <Helmet>
<title>Web Design  - The Designsinc.</title>
</Helmet>
      <div className="bg-center bg-no-repeat bg-cover bg-hero-pattern">
        <Navbar />

        {/** About Section */}
        <WebDesignHero />
      </div>

    {/**Slider logos company */}
    <PartnersLogos />

      {/**idea section and btns */}
      <IdeasWebSection />

       {/** Web Portfolios */}
       <h2 className="text-center p-3 font-bold text-[36px] text-white">Web Portfolio</h2>
      <WebPortfolio />

      {/** Testimonials */}
      <div className="">
        <Feedbacks />
      </div>
      {/** contact section */}
      <div className="relative z-0">
        <Contact />
      
      </div>

      <Footer />
    </div>
  );
}

export default WebsiteDesign;
