import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { gql, GraphQLClient } from "graphql-request";
import ReactHtmlParser from "react-html-parser";
import Navbar from "./Navbar";
import Footer from "./Footer";


const SINGLE_POST_QUERY = gql`
  query SinglePostQuery($slug: String!) {
    postes(where: { slug: $slug }) {
      id
      title
      coverPhoto {
        url
      }
      content {
        html
      }
    }
  }
`;

function SinglePost() {
  const { slug } = useParams();
  const navigate = useNavigate(); // Use the useNavigate hook
  const [post, setPost] = useState(null);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );

    const fetchPost = async () => {
      try {
        const data = await client.request(SINGLE_POST_QUERY, { slug });
        setPost(data.postes[0]);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [slug]);



  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className=" relative z-0 bg-[#060816]">
      <Navbar />
      <div className="container w-full mx-auto mt-10">
        <div className="content-start">
        <div className="bg-cover bg-div">
          <img
            src={post.coverPhoto.url}
            alt="Post Image"
            className="bg-image"
          />
        </div>
        <h1 className="text-2xl font-semibold">{post.title}</h1>
        <div className="">{ReactHtmlParser(post.content.html)}</div>
        </div>

       
      </div>
      <Footer />
    </div>
  );
}

export default SinglePost;
