import React from "react";

import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constant";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { aboutHeroImg, circleaboutimage } from "../assets";



const ServiceCard = ({ index, title, icon }) => {
  return (
    <div className="xs:w-[250px] w-full ">
      <div
        
        className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card"
      >
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
        >
          <img src={icon} alt={title} className="object-contain w-16 h-16" />
          <h3 className="text-white text-[20px] font-bold text-center">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};

function AboutUSPage() {
  return (
    <>
      <div className="flex flex-col items-center justify-end pt-10 2xl:flex-row xl:flex-row lg:flex-row md:flex-row gap-x-28 ">
        <div>
          <div >
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              OUR STORY
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
              We’re A Team Of Creative Individuals.
            </h2>
          </div>

          <p
            className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
            
          >
            The company has been on the market for a decade growing the
            diversity of services year by year. Now, Web Design Inc is a
            full-stack digital agency with all the specialists required for the
            efficient creative process from scratch. The agency departments are
            organized around different stages of user-friendly product
            development. Solid project management based on the best world
            practices allows us to run complex projects for customers around the
            world and provide high-quality outcomes.
          </p>
        </div>
        <div className="w-1/2">
          <div >
            <img
              src={aboutHeroImg}
              className="object-contain w-100 h-100"
              alt="img"
            />
          </div>
        </div>
      </div>

     

     
    </>
  );
}

export default sectionWrapper(AboutUSPage);
