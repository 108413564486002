import React, { useEffect, useState } from "react";
import { sectionWrapper } from "../hoc";
import { motion } from "framer-motion";

import Lottie from "lottie-react";

import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { phoneIconjn, calljsicon,emailIconjn, ecomCart} from "../assets";


function IdeaEcomSection() {
  return (
    <div>
      <div className="flex flex-col items-center justify-between xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row ">
        <div>
          <div >
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
            Welcome to The Designs Inc., your gateway to an extraordinary eCommerce experience!
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
            Tailored Shopping Experience.
            </h2>

            <p
              className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
              
            >
             We understand that every business is unique, and your eCommerce website should reflect that. Our team of experts will work closely with you to design a custom online store that aligns perfectly with your brand, products, and target audience.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-6 xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row">
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={emailIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">info@thedesignsinc.com</span>
                </div>
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={phoneIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">(718) 785-3776</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          {/*
          <Lottie options={defaultOptions} height={400} width={400} />

  */}
          <Lottie animationData={ecomCart} />
        </div>
      </div>
    </div>
  );
}

export default sectionWrapper(IdeaEcomSection);
