import React from 'react'
import { useState } from 'react';
import { sectionWrapper } from "../hoc";
import AppGalleries from './AppGalleries';
import EcomPortfolio from './EcomPortfolio';
import LogoPortfolio from './LogoPortfolio';
import PortfolioAppGallery from './PortfolioAppGallery';
import WebPortfolio from './WebPortfolio';


function HomePortfolio() {

    const [activeTab, setActiveTab] = useState(2);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <div>
        
        <ul className="flex items-center justify-center text-center cursor-pointer">
      
        <li
          className={`mr-1 ${activeTab === 2 ? 'border-l cursor-pointer  rounded-md border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(2)}
        >
          Web Design
        </li>
        <li
          className={`mr-1 ${activeTab === 3 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(3)}
        >
          E-Commerce
        </li>
        
        <li
          className={`mr-1 ${activeTab === 4 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(4)}
        >
          Mobile Apps
        </li>
      </ul>
      <div className="p-4 ">
      
        {activeTab === 2 && (
          <div>
            <WebPortfolio />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <EcomPortfolio />
          </div>
        )}
         {activeTab === 4 && (
          <div>
            <PortfolioAppGallery />
            <AppGalleries />
          </div>
        )}
        
      </div>
    </div>
  )
}

export default HomePortfolio;