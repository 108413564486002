import React, { useEffect, useState } from "react";
import { gql, GraphQLClient } from "graphql-request";
import ReactHtmlParser from "react-html-parser";

const MY_QUERY = gql`
  query MyQuery {
    postes {
      id
      title
      coverPhoto {
        url
      }
      content {
        html
      }
      slug
    }
  }
`;

function BlogPosts() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );

    const fetchData = async () => {
      try {
        const data = await client.request(MY_QUERY);
        setData(data);
        console.log("Data", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto mt-10">
      <div className="flex flex-wrap -mx-2">
        {data.postes.map((post) => (
          <div
            key={post.id}
            className="w-full px-2 mb-6 sm:w-1/2 lg:w-1/4"
          >
            <img src={post.coverPhoto.url} alt="Post Image" />
            <h1 className="text-2xl font-semibold">{post.title}</h1>
            <p className="clamp-3">{ReactHtmlParser(post.content.html)}</p>
            <button
              type="submit"
              className="bg-tertiary w-fit py-3 px-2 outline-none border-none font-medium text-[15px] shadow-md shadow-primary rounded-xl flex items-center justify-center"
            >
              <a
                href={"/posts/" + post.slug}
                target="_blank"
                className="text-white"
              >
                Read More
              </a>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogPosts;
