import React from "react";

import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constant";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { aiimg, circleaboutimage, circleVideo, ecommerceheri } from "../assets";

const ServiceCard = ({ index, title, icon }) => {
  return (
    <div className="xs:w-[250px] w-full ">
      <div className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card">
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
        >
          <img src={icon} alt={title} className="object-contain w-16 h-16" />
          <h3 className="text-white text-[20px] font-bold text-center">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};

function EcommerceHero() {
  return (
    <>
      <div className="flex flex-col items-center justify-end pt-20 2xl:flex-row xl:flex-row lg:flex-row md:flex-row gap-x-28 ">
        <div className="w-full 2xl:w-1/2 xl:2xl:w-1/2 lg:2xl:w-1/2 md:2xl:w-1/2 ">
          <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              DESIGN IS POWERFUL
            </p>
            <h2 className="text-white font-black md:text-[40px] sm:text-[40px] xs:text-[20px] text-[30px]">
              Creating e-commerce websites that drive sales and revenue.
            </h2>
          </div>

          <p className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]">
            At Thedesignsinc, we specialize in creating exceptional e-commerce
            websites that are tailored to meet the unique needs of your
            business. Our team of skilled designers and developers work closely
            with you to understand your brand, your products, and your target
            audience. From there, we craft a custom e-commerce solution that is
            both visually stunning and highly functional, designed to drive
            sales and revenue. With a focus on creating a seamless shopping
            experience for your customers, we empower your online business to
            thrive in the competitive e-commerce landscape.
          </p>
        </div>
        <div className="w-full 2xl:w-1/2 xl:2xl:w-1/2 lg:2xl:w-1/2 md:2xl:w-1/2 ">
          <div>
            <img src={ecommerceheri} className="w-500 h-500 " alt="img" />
          </div>
        </div>
      </div>

      
    </>
  );
}

export default sectionWrapper(EcommerceHero);
