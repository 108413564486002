

import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import About from '../components/About'
import Experience from '../components/Experience'
import Feedbacks from '../components/Feedbacks'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import HomePortfolio from '../components/HomePortfolio'
import { Helmet } from 'react-helmet';
import Carousel from '../components/Carousel'
import PartnersLogos from '../components/PartnersLogos'
import PartnersTDI from '../components/PartnersTDI'




export default function Home() {
  return (
    <div className='relative z-0 bg-primary'>
      <Helmet>
        <title>Custom Website Design & Development - The Designsinc.</title>
      </Helmet>
     <div className='bg-center bg-no-repeat bg-cover bg-hero-pattern'>
  <Navbar />
  <Hero  />
</div>
      <About className="mt-10" />
  
      <PartnersLogos />
      <Experience />
      <HomePortfolio />
      <Feedbacks />
      <div className='relative z-0'>
        <Contact />

        <PartnersTDI />
        
       
      </div>

      <Footer />

    </div>
  )
}


