
import EcomPortfolio from "../components/EcomPortfolio";
import Footer from "../components/Footer";
import LogoPortfolio from "../components/LogoPortfolio";
import Navbar from "../components/Navbar";
import WebPortfolio from "../components/WebPortfolio";
import { sectionWrapper } from "../hoc";

import PortfolioHero from "../section/PortfolioHero";

import { Helmet } from 'react-helmet';
import { useState } from 'react';
import PortfolioAppGallery from "../components/PortfolioAppGallery";
import AppGalleries from "../components/AppGalleries";





function Portfolio() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="relative z-0 bg-[#060816]">
       <Helmet>
        <title>Portfolios Page - The Designsinc.</title>
      </Helmet>
    <div>
      <Navbar />
      <PortfolioHero />
      <ul className="flex items-center justify-center text-center cursor-pointer">
        <li
          className={`mr-1 ${activeTab === 1 ? 'border-l cursor-pointer border-1 rounded-md bg-[#0b081c] p-5 px-5 text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(1)}
        >
          Logo Design
        </li>
        <li
          className={`mr-1 ${activeTab === 2 ? 'border-l cursor-pointer  rounded-md border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(2)}
        >
          Web Design
        </li>
        <li
          className={`mr-1 ${activeTab === 3 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(3)}
        >
          E-Commerce
        </li>
        
        <li
          className={`mr-1 ${activeTab === 4 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(4)}
        >
          Mobile Apps
        </li>
      </ul>
      <div className="p-4 ">
        {activeTab === 1 && (
          <div>
            <LogoPortfolio />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <WebPortfolio />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <EcomPortfolio />
          </div>
        )}
         {activeTab === 4 && (
          <div>
             {/**Mobile Apps Show Case */}
        <PortfolioAppGallery />
        <AppGalleries />
          </div>
        )}
        
      </div>

      <Footer />
    </div>
    </div>
  );
}

export default Portfolio;
