import React, { useEffect, useState } from "react";
import { sectionWrapper } from "../hoc";
import { motion } from "framer-motion";
import { AiFillCheckCircle } from "react-icons/ai";
import Lottie from "lottie-react";

import { fadeIn, textVariant, slideIn } from "../utils/motion";
import {
  phoneIconjn,
  calljsicon,
  emailIconjn,
  ecomCart,
  ContentLot,
  SeoImport,
} from "../assets";

function IdeaSeoSect() {
  return (
    <div>
      <div className="flex flex-col items-center justify-between xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row ">
        <div>
          <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              Welcome to The Designs Inc., your partner in driving organic
              growth through exceptional SEO services!
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
              Keyword Research and Optimization.
            </h2>

            <p className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]">
              Targeting the right keywords is vital for SEO success. Our team
              conducts in-depth keyword research to identify high-value and
              relevant keywords that drive qualified traffic to your website.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-6 xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row">
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={emailIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">info@thedesignsinc.com</span>
                </div>
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={phoneIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">(718) 785-3776</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          {/*
          <Lottie options={defaultOptions} height={400} width={400} />

  */}
          <Lottie animationData={SeoImport} />
        </div>
      </div>

      {/** Why CHoose US Section */}
      <div className="">
        {/** Main Section */}
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** Frst 2 colmn */}

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Proven Expertise: </h1>
              <p>
                Our SEO team comprises skilled professionals with a track record
                of delivering tangible results. We stay updated with the latest
                search engine algorithms and trends to optimize your website
                effectively.
              </p>
            </div>
          </div>

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Customized Strategies:</h1>
              <p>
                We understand that every business is unique. Our SEO services
                are tailored to your specific goals, whether it's increasing
                website traffic, improving conversion rates, or expanding your
                online presence.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 2nd 2 colmn */}

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                Comprehensive Website Analysis:
              </h1>
              <p>
                Before crafting an SEO strategy, we conduct a thorough analysis
                of your website's strengths, weaknesses, and opportunities. This
                helps us identify areas for improvement and devise data-driven
                solutions.
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                Keyword Research and Optimization:{" "}
              </h1>
              <p>
                Targeting the right keywords is vital for SEO success. Our team
                conducts in-depth keyword research to identify high-value and
                relevant keywords that drive qualified traffic to your website.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 3rd 2 colmn */}

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">On-Page Optimization:</h1>
              <p>
                From meta tags to content structure, we optimize every aspect of
                your website to improve its visibility and relevancy in search
                engine results.
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                High-Quality Content Creation:
              </h1>
              <p>
                Content is at the heart of SEO. Our skilled writers craft
                informative, engaging, and SEO-friendly content that resonates
                with your audience and encourages organic linking.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 4th 2 colmn */}

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Link Building Strategies:</h1>
              <p>
                We employ ethical link-building techniques to strengthen your
                website's authority and credibility, fostering long-term organic
                growth.
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Local SEO Focus: </h1>
              <p>
                For businesses targeting local audiences, our local SEO
                strategies ensure your website appears prominently in local
                search results, attracting customers in your vicinity.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 5th 2 colmn */}

          <div className="flex p-5 align-top rounded-sm bg-tertiary flex-rowsm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Transparent Reporting:</h1>
              <p>
                We believe in complete transparency. Our regular reports provide
                detailed insights into the performance of your SEO campaigns and
                the progress of key performance indicators (KPIs).
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Continuous Optimization: </h1>
              <p>
                SEO is an ongoing process. We continuously monitor and analyze
                results, making data-driven adjustments to ensure your SEO
                strategies evolve with changing market dynamics.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/** End Section ~ Why CHoose US Section */}
    </div>
  );
}

export default sectionWrapper(IdeaSeoSect);
