import React, { useEffect, useState } from "react";
import { sectionWrapper } from "../hoc";
import { motion } from "framer-motion";

import Lottie from "lottie-react";

import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { phoneIconjn, calljsicon,emailIconjn} from "../assets";


function IdeaSectionAbout() {
  return (
    <div>
      <div className="flex flex-col items-center justify-between xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row ">
        <div>
          <div >
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              GOT A PROJECT IN MIND, LET’S GRAB A COFFEE
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
              We’re A Team Of Creative Individuals.
            </h2>

            <p
              className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
              
            >
              We are a full service digital consultancy with experience and
              capacity to meet the needs of even the largest most complex of
              organizations in the world. Our services portfolio enables us to
              offer clients the best of experiences.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-6 xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row">
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={emailIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">info@thedesignsinc.com</span>
                </div>
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={phoneIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">(718) 785-3776</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          {/*
          <Lottie options={defaultOptions} height={400} width={400} />

  */}
          <Lottie animationData={calljsicon} />
        </div>
      </div>
    </div>
  );
}

export default sectionWrapper(IdeaSectionAbout);
