import React, { useState, useEffect } from "react";
import { gql, GraphQLClient } from "graphql-request";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

const MY_QUERY = gql`
  query MyQuery {
    t_Partners {
      id
      pictures {
        url
      }
    }
  }
`;

function Carousel1({ images }) {
  const [showMore, setShowMore] = useState(false);
  const ROWS_TO_SHOW = 2;
  const IMAGES_PER_ROW = 5;

  const totalImages = images.length;

  const portfolioSlice = showMore
    ? images
    : images.slice(0, ROWS_TO_SHOW * IMAGES_PER_ROW);

  const handleLoadMore = () => {
    setShowMore(true);
  };

  return (
    <>
     
      <div className="container w-full mx-auto mt-10">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={4}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          className="swiper_container"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image.url} alt={`Logo ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

function PartnersLogos() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  const images = data.t_Partners[0].pictures;

  return <Carousel1 images={images} />;
}

export default PartnersLogos;
