import React from "react";


import Carousel from "../components/Carousel";
import Navbar from "../components/Navbar";


import AboutUSPage from "../section/AboutUSPage";
import IdeaSectionAbout from "../section/IdeaSectionAbout";
import Feedbacks from "../components/Feedbacks";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function ForZerFor() {
  return (
    <div className="relative z-0 bg-[#060816]">
      <div>
        <Navbar />
        <div>
         
          
          <div className='relative z-0'>
        <Contact />
       
      </div>
        </div>
      </div>

     

      <Footer />
    </div>
  );
}

export default ForZerFor;
