import { close, logo, menu } from '../assets'
import { navLinks } from '../constant'

import React, { useState } from 'react'
import { styles } from '../styles'



;
import { Link } from 'react-router-dom';

function Navbar() {


  const [isOpen, setIsOpen] = useState(false);
  const [Active, SetActive] = useState("");
  const [toggle, setToggle] = useState(false);
  
  const toggleNav = () => {
    setIsOpen(!isOpen);
  }


  return (
    <nav className={`${styles.paddingX} w-full flex justify-evenly items-center py-5 fixed top-0 z-20 bg-[#060816]`}>
      <div className="items-center flex-shrink-0 mr-6 text-white ">
        <span className="text-xl font-semibold tracking-tight"><img src={logo} alt="logo" className="object-contain w-50 h-50" /></span>
      </div>
      <div className="w-full fblock lg:flex lg:items-center lg:w-auto">
        <div className="hidden text-sm lg:flex-grow 2xl:flex xl:flex lg:flex md:flex">
       <a   href="/" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            Home
          </a>
         <a  href="/about" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            About
          </a>
          <div className="relative inline-block dropdown">
           <a  href="#responsive-header" className=" text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
              Services
            </a>
            <ul className="absolute hidden pt-1 text-gray-700 w-60 dropdown-menu">
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/webdesign" className="hover:text-white">Web</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/e-commerce-services" className="hover:text-white">E-Commerce</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/digital-marketing" className="hover:text-white">Marketing</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/3d-motion-graphic" className="hover:text-white">Motions</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/mobile-app-services" className="hover:text-white">Apps</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/modern-logo-design" className="hover:text-white">Logo</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/web-content-writing" className="hover:text-white">Content</a>
              </li>
              
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/search-engine-optimization" className="hover:text-white">SEO</a>
              </li>

              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/social-media-marketing" className="hover:text-white">Social</a>
              </li>
            </ul>
          </div>

         

          <div className="relative inline-block dropdown">
           <a  href="/portfolio" className=" text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
              Portfolio
            </a>
            <ul className="absolute hidden pt-1 text-gray-700 w-60 dropdown-menu">
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/logo-porfolio" className="hover:text-white">Logo</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/web-porfolio" className="hover:text-white">Web</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/ecommerce-porfolio" className="hover:text-white">E-Commerce</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/app-portfolio" className="hover:text-white">Apps</a>
              </li>
              
            </ul>
          </div>

         <a  href="/blog" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            Blog
          </a>
         <a  href="/contact" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            Contact
          </a>


        </div>

        {/** Mobile device */}
        

        <img
            src={toggle ? close : menu}
            className="ml-auto cursor-pointer w-9 h-9 sm:hidden" 
            alt="icon-menu"
            onClick={() => setToggle(!toggle)}
          />

        <div onClick={toggleNav} className={`${!toggle ? "hidden" : "flex"} flex-col  text-sm lg:flex-grow 2xl:hidden xl:hidden lg:hidden md:hidden p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}>
         <a  href="/" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            Home
          </a>
         <a  href="/about" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            About
          </a>
          <div className="relative inline-block dropdown">
           <a  href="#responsive-header" className=" text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
              Services
            </a>
            <ul className="absolute hidden pt-1 text-gray-700 w-60 dropdown-menu">
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/webdesign" className="hover:text-white">Web</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/e-commerce-services" className="hover:text-white">E-Commerce</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/digital-marketing" className="hover:text-white">Marketing</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/3d-motion-graphic" className="hover:text-white">Motions</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/mobile-app-services" className="hover:text-white">Apps</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/modern-logo-design" className="hover:text-white">Logo</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/web-content-writing" className="hover:text-white">Content</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/search-engine-optimization" className="hover:text-white">SEO</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/social-media-marketing" className="hover:text-white">Social</a>
              </li>
            </ul>
          </div>

         

          <div className="relative inline-block dropdown">
          <a  href="/portfolio" className=" text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
              Portfolio
            </a>
            <ul className="absolute hidden pt-1 text-gray-700 w-60 dropdown-menu">
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/logo-porfolio" className="hover:text-white">Logo</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/web-porfolio" className="hover:text-white">Web</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/ecommerce-porfolio" className="hover:text-white">E-Commerce</a>
              </li>
              <li className="block px-4 py-2 whitespace-no-wrap bg-gray-200 hover:bg-gray-400">
               <a  href="/app-portfolio" className="hover:text-white">Apps</a>
              </li>
              
            </ul>
          </div>

         <a  href="/blog" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            Blog
          </a>
         <a  href="/contact" className="text-[18px] font-medium cursor-pointer block mt-4 mr-4 text-white lg:inline-block lg:mt-0 hover:text-gray-400">
            Contact
          </a>



        </div>





        
      </div>
    </nav>
  )
}

export default Navbar