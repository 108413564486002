
import ComboPackages from "../components/ComboPackages";
import EcommercePackages from "../components/EcommercePackages";
import Footer from "../components/Footer";
import LogoDesignPackage from "../components/LogoDesignPackage";
import MarketingPackages from "../components/MarketingPackages";
import MotionPackages from "../components/MotionPackages";
import Navbar from "../components/Navbar";
import WebDesignPackage from "../components/WebDesignPackage";
import { sectionWrapper } from "../hoc";
import PackagesHero from "../section/PackagesHero";
import { Helmet } from 'react-helmet';


import { useState } from 'react';
import SubsPackages from "../components/SubsPackages";



function Subscriptions() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="relative z-0 bg-[#060816]">
      <Helmet>
        <title>Subscriptions Packages - The Designsinc.</title>
      </Helmet>
    <div>
      <Navbar />
      <PackagesHero />
      <ul className="flex items-center justify-center text-center cursor-pointer">
        <li
          className={`mr-1 ${activeTab === 1 ? 'border-l cursor-pointer border-1 rounded-md bg-[#0b081c] p-5 px-5 text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(1)}
        >
          Subscriptions
        </li>
        
       
      </ul>
      <div className="p-4 ">
        {activeTab === 1 && (
          <div>
            <SubsPackages />
          </div>
        )}
       
      </div>

      <Footer />
    </div>
    </div>
  );
}

export default Subscriptions;
