
import EcomPortfolio from "../components/EcomPortfolio";
import Footer from "../components/Footer";
import LogoPortfolio from "../components/LogoPortfolio";
import Navbar from "../components/Navbar";
import WebPortfolio from "../components/WebPortfolio";
import { sectionWrapper } from "../hoc";

import PortfolioHero from "../section/PortfolioHero";


import { useState } from 'react';
import PortfolioAppGallery from "../components/PortfolioAppGallery";
import AppGalleries from "../components/AppGalleries";
import { Helmet } from 'react-helmet';




function LogoPage() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="relative z-0 bg-[#060816]">
       <Helmet>
        <title>Logo Portfolio- The Designsinc.</title>
      </Helmet>
      
    <div>
      <Navbar />
      <PortfolioHero />
      <div  className="pb-5" >
            <LogoPortfolio/>
            </div>
         

      <Footer />
    </div>
    </div>
  );
}

export default LogoPage;

