import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import React from 'react'
import { Helmet } from 'react-helmet';


function ContactPage() {
  return (
    <div className='relative z-0 bg-[#060816]'>
       <Helmet>
        <title>Contact Us - The Designsinc.</title>
      </Helmet>
    <div className=''>
      <Navbar />
      <div>
        {/** Contact Section from components comes through */}
        <div className='relative z-0'>
        <Contact />
       
      </div>
        </div>
    </div>
   
    <Footer />

  </div>
  )
}

export default ContactPage