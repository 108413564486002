import React, { useEffect, useState,useRef } from "react";
import { sectionWrapper } from "../hoc";
import { motion } from "framer-motion";


import Lottie from "lottie-react";

import { fadeIn, textVariant, slideIn } from "../utils/motion";
import {
  phoneIconjn,
  calljsicon,
  emailIconjn,
  ecomCart,
  ContentLot,
  SeoImport,
  SocialSeo,
} from "../assets";
import { AiFillCheckCircle } from "react-icons/ai";

function IdeaSSMSect() {

  
    
  return (
    <div>
      <div className="flex flex-col items-center justify-between xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row ">
        <div>
        <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              Welcome to The Designs Inc., your partner in driving organic
              growth through exceptional SEO services!
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
              Ignite Your Brand's Social Media Success.
            </h2>

            <p className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]">
              Ignite your brand's social media success with SocialXcel! Our
              expert team harnesses the power of social media platforms to
              propel your brand forward. From engaging content creation to
              targeted ad campaigns, we unlock the keys to increased visibility,
              customer engagement, and amplified brand influence.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-6 xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row">
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={emailIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">info@thedesignsinc.com</span>
                </div>
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={phoneIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">(718) 785-3776</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          {/*
          <Lottie options={defaultOptions} height={400} width={400} />

  */}
          <Lottie animationData={SocialSeo} />
        </div>
      </div>

      {/** Why CHoose US Section */}
      <div className="">
        {/** Main Section */}
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** Frst 2 colmn */}

        

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Strategic Approach:</h1>
              <p>
                Social media marketing is not just about posting content; it's
                about crafting a well-thought-out strategy. Our team formulates
                data-driven social media plans aligned with your business
                objectives.
              </p>
            </div>
          </div>

       

         
          <div  className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Platform Expertise:</h1>
              <p>
                From Facebook to Instagram, LinkedIn to Twitter, we have
                expertise across all major social media platforms. We tailor our
                strategies to each platform's unique dynamics and audience
                behavior.
              </p>
            </div>
          </div>

       
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 2nd 2 colmn */}

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Engaging Content Creation:</h1>
              <p>
                Compelling content is the cornerstone of social media success.
                Our creative team designs visually appealing and shareable
                content that resonates with your audience, boosting engagement
                and brand loyalty.
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Community Building: </h1>
              <p>
                We believe in fostering a genuine community around your brand.
                Our social media efforts focus on building meaningful
                connections, responding to comments, and engaging in
                conversations with your followers.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 3rd 2 colmn */}

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Influencer Marketing:</h1>
              <p>
                Leveraging the power of influencers can amplify your brand's
                reach. We identify relevant influencers and collaborate to reach
                wider audiences and gain credibility in your industry.
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                Paid Advertising Strategies:{" "}
              </h1>
              <p>
                Social media advertising can deliver instant results. We create
                targeted ad campaigns that maximize your ROI and help you reach
                specific demographics and user segments.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 4th 2 colmn */}

          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                Social Listening and Monitoring:{" "}
              </h1>
              <p>
                We keep a close eye on social media conversations around your
                brand. This allows us to address concerns, gather feedback, and
                understand your audience's sentiments better.
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Measurable Results:</h1>
              <p>
                Social media marketing is not a guessing game. Our detailed
                analytics and reports provide insights into the performance of
                your campaigns, allowing us to fine-tune strategies for optimum
                results.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 sm:space-x-5 xl:space-x-5 2xl:space-x-5 sm:flex-row xl:flex-row 2xl:flex-row">
          {/** 5th 2 colmn */}

          <div className="flex p-5 align-top rounded-sm bg-tertiary flex-rowsm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Brand Consistency:</h1>
              <p>
                We ensure that your brand's voice, tone, and visual identity
                remain consistent across all social media channels, creating a
                cohesive and recognizable brand image.
              </p>
            </div>
          </div>
          <div className="flex flex-row p-5 align-top rounded-sm bg-tertiary sm:w-1/2 xl:sm:w-1/2 2xl:sm:w-1/2">
            <div>
              <AiFillCheckCircle size={25} color="green" />
            </div>
            <div>
              <h1 className="text-2xl font-bold">Adaptable and Agile:</h1>
              <p>
                Social media trends change rapidly, and we're always ready to
                adapt. Our agile approach ensures that we stay ahead of the
                curve and capitalize on emerging opportunities.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/** End Section ~ Why CHoose US Section */}
    </div>
  );
}

export default sectionWrapper(IdeaSSMSect);
