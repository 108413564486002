import React from "react";

import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constant";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { aboutHeroImg, circleaboutimage } from "../assets";

function AboutBlogPage() {
  return (
    <>
      <div className="flex flex-col items-center justify-end pt-10 2xl:flex-row xl:flex-row lg:flex-row md:flex-row gap-x-28 ">
        <div>
          <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              OUR STORY
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
              We’re A Team Of Creative Individuals.
            </h2>
          </div>

          <p className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]">
            We're a team of creative individuals who have come together to make
            your digital dreams a reality. With a decade of experience in the
            industry, we've been steadily expanding our range of services year
            after year. Today, we proudly stand as Web Design Inc, a full-stack
            digital agency equipped with a diverse team of specialists, ready to
            embark on the journey of turning your ideas into reality.
          </p>
        </div>
        <div className="w-1/2">
          <div>
            <img
              src={aboutHeroImg}
              className="object-contain w-100 h-100"
              alt="img"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default sectionWrapper(AboutBlogPage);
