
import ComboPackages from "../components/ComboPackages";
import EcommercePackages from "../components/EcommercePackages";
import Footer from "../components/Footer";
import LogoDesignPackage from "../components/LogoDesignPackage";
import MarketingPackages from "../components/MarketingPackages";
import MotionPackages from "../components/MotionPackages";
import Navbar from "../components/Navbar";
import WebDesignPackage from "../components/WebDesignPackage";
import { sectionWrapper } from "../hoc";
import PackagesHero from "../section/PackagesHero";
import { Helmet } from 'react-helmet';


import { useState } from 'react';



function Packages() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="relative z-0 bg-[#060816]">
      <Helmet>
        <title>Packages - The Designsinc.</title>
      </Helmet>
    <div>
      <Navbar />
      <PackagesHero />
      <ul className="flex items-center justify-center text-center cursor-pointer">
        <li
          className={`mr-1 ${activeTab === 1 ? 'border-l cursor-pointer border-1 rounded-md bg-[#0b081c] p-5 px-5 text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(1)}
        >
          Logo Design
        </li>
        <li
          className={`mr-1 ${activeTab === 2 ? 'border-l cursor-pointer  rounded-md border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(2)}
        >
          Web Design
        </li>
        <li
          className={`mr-1 ${activeTab === 3 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(3)}
        >
          E-Commerce
        </li>
        <li
          className={`mr-1 ${activeTab === 4 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(4)}
        >
          Motion
        </li>
        <li
          className={`mr-1 ${activeTab === 5 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(5)}
        >
          Marketing
        </li>
        <li
          className={`mr-1 ${activeTab === 6 ? 'border-l rounded-md cursor-pointer  border-r rounded-t p-5 px-5 bg-[#0b081c] text-white' : 'border-gray-400 green-pink-gradient p-5 px-5 rounded-md'}`}
          onClick={() => handleTabClick(6)}
        >
          Combo
        </li>
      </ul>
      <div className="p-4 ">
        {activeTab === 1 && (
          <div>
            <LogoDesignPackage />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <WebDesignPackage />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <EcommercePackages />
          </div>
        )}
        {activeTab === 4 && (
          <div>
            <MotionPackages />
          </div>
        )}
        {activeTab === 5 && (
          <div>
            <MarketingPackages />
          </div>
        )}
         {activeTab === 6 && (
          <div>
            <ComboPackages  />
          </div>
        )}
      </div>

      <Footer />
    </div>
    </div>
  );
}

export default Packages;
