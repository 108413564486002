import React from "react";
import { styles } from "../styles";
import { useState, useRef,useEffect } from "react";

import emailjs from '@emailjs/browser';
import { headerImageHome, webconcepts01 } from "../assets";
import { motion } from "framer-motion";
import "../index.css";
import axios from "axios";
import Lottie from "lottie-react";
import { phoneIconjn, calljsicon,emailIconjn} from "../assets";
import { slideIn } from "../utils/motion";
import { gql, GraphQLClient } from "graphql-request";


const MY_QUERY = gql`
  query MyQuery {
    thirdparty {
      id
     
      video 
    }
  }
`;


const Hero = () => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  
  const videoURL = data.thirdparty[0].video; // Extract the video URL from the response






 
  return (
    <section className="relative w-full h-screen">
    {/** Add video element as background */}
    <video
      autoPlay
      loop
      muted
      className="absolute inset-0 object-cover w-full h-full"
      poster="path-to-your-poster-image.jpg" // Replace with the path to your poster image
    >
      <source
          src={videoURL ? videoURL : "https://res.cloudinary.com/jewerlysvilla/video/upload/v1690582067/tdi_header_vid_ozd6dp.mp4"}
          type="video/mp4"
        />
     
     {/**  https://res.cloudinary.com/jewerlysvilla/video/upload/v1690582067/tdi_header_vid_ozd6dp.mp4   */}
      
      {/* Add additional source elements for other video formats (e.g., webm) if needed */}
    </video>
  
    {/** No content here, it's just a video background */}
  </section>
  );
};

export default Hero;
