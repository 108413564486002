import React, { useEffect, useState } from "react";
import { sectionWrapper } from "../hoc";
import { motion } from "framer-motion";

import Lottie from "lottie-react";

import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { phoneIconjn, calljsicon, emailIconjn, webLottie, marketing } from "../assets";

function IdeaMarketingSect() {
  return (
    <div>
      <div className="flex flex-col items-center justify-between xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row ">
        <div>
          <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              Welcome to The Designs Inc., where creativity meets results-driven
              marketing strategies!
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
              Custom-Tailored Campaigns.
            </h2>

            <p className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]">
              Your brand is unique, and your marketing campaigns should reflect
              that. Our team takes the time to understand your business, target
              audience, and objectives to create personalized marketing
              strategies that deliver maximum impact.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-6 xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row">
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={emailIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">info@thedesignsinc.com</span>
                </div>
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={phoneIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">(718) 785-3776</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          {/*
          <Lottie options={defaultOptions} height={400} width={400} />

  */}
          <Lottie className="w-96" animationData={marketing} />
        </div>
      </div>
    </div>
  );
}

export default sectionWrapper(IdeaMarketingSect);
