import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';
import { motion } from "framer-motion";

import { fadeIn } from "../utils/motion";



import { shopiimage,phpimage,reactnimage,reactimage,figmaimage } from '../assets';
import { sectionWrapper } from '../hoc';


import SwiperCore, { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';







SwiperCore.use([EffectCoverflow, Pagination, Navigation, Autoplay]);


function Carousel() {
  return (
    <div className="container w-full mx-auto mt-10">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={4}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={shopiimage} alt="slide_img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={figmaimage} alt="slide_img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={reactimage} alt="slide_img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={shopiimage} alt="slide_img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={phpimage} alt="slide_img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={reactimage} alt="slide_img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={phpimage} alt="slide_img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={figmaimage} alt="slide_img" />
        </SwiperSlide>
        {/* Add more SwiperSlides as needed */}
      </Swiper>
    </div>
  );
}

export default Carousel;