import React, { useState, useEffect } from "react";
import { gql, GraphQLClient } from "graphql-request";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

const MY_QUERY = gql`
  query MyQuery {
    thirdparty {
      id
      pictures {
        url
      }
    }
  }
`;

function Carousel1({ images }) {
  return (
    <div className="container w-full mx-auto mt-10">
      <Swiper
        loop={true}
        slidesPerView={6} // Show 6 images in a row
        spaceBetween={10} // Add some space between images
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        className="swiper_container"
      >
        {images.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "100%", // To display 6 images in a row
                padding: "10px", // To add spacing between images
              }}
            >
              <img
                src={image.url}
                alt={`Logo ${index}`}
                style={{
                 maxWidth:'100',
                objectFit: "contain", // Maintain aspect ratio without cutting edges
                   // Set the maximum height for the images
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

function PartnersTDI() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  const images = data.thirdparty[0].pictures;

  return <Carousel1 images={images} />;
}

export default PartnersTDI;
