import React, { useState, useEffect } from "react";
import { sectionWrapper } from "../hoc";
import { gql, GraphQLClient } from "graphql-request";
import { HiArrowRight } from "react-icons/hi";


const MY_QUERY = gql`
query MyQuery {
    appGalleries {
      id
      images {
        url
      }
    }
  }
`;

function AppGalleries() {
  const [data, setData] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const ROWS_TO_SHOW = 2;
  const IMAGES_PER_ROW = 5;

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  const totalImages = data.appGalleries[0].images.length;

  const portfolioSlice = showMore
    ? data.appGalleries[0].images
    : data.appGalleries[0].images.slice(0, ROWS_TO_SHOW * IMAGES_PER_ROW);

  const handleLoadMore = () => {
    setShowMore(true);
  };

  return (
    <div className="flex flex-wrap items-start justify-center gap-x-4">
      
      {portfolioSlice.map((image, index) => (
        <div
          key={index}
          className={`w-full sm:w-1/${IMAGES_PER_ROW} md:w-1/${IMAGES_PER_ROW} xl:w-1/${IMAGES_PER_ROW} my-5`}
        >
          <div className="w-full">
            <img src={image.url} className="w-full h-full rounded-md" alt={`Logo ${index}`} />
          </div>
        </div>
      ))}
      {!showMore && totalImages > ROWS_TO_SHOW * IMAGES_PER_ROW && (
        <div className="w-full mt-4 text-center">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default AppGalleries;
